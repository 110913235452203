var data = {
    "0": {
        "param_order": [
            "0", 
            "1", 
            "2", 
            "3", 
            "4", 
            "8"
        ], 
        "parameters": {
            "0": {
                "name": {
                    "label": "durum", 
                    "translation": {
                        "en": "status", 
                        "tr": "durum"
                    }, 
                    "value": "0"
                }, 
                "options": [
                    {
                        "label": "pasif", 
                        "translation": {
                            "en": "passive", 
                            "tr": "pasif"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "aktif", 
                        "translation": {
                            "en": "active", 
                            "tr": "aktif"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "1": {
                "name": {
                    "label": "güncelleme tarihi", 
                    "translation": {
                        "en": "update date", 
                        "tr": "güncelleme tarihi"
                    }, 
                    "value": "1"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "datetime"
            }, 
            "2": {
                "name": {
                    "label": "doküman kodu", 
                    "translation": {
                        "en": "document code", 
                        "tr": "doküman kodu"
                    }, 
                    "value": "2"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }, 
            "3": {
                "name": {
                    "label": "sözleşme tipi", 
                    "translation": {
                        "en": "contract type", 
                        "tr": "sözleşme tipi"
                    }, 
                    "value": "3"
                }, 
                "options": [
                    {
                        "label": "genel", 
                        "translation": {
                            "en": "general", 
                            "tr": "genel"
                        }, 
                        "value": "0"
                    }, 
                    {
                        "label": "ürün", 
                        "translation": {
                            "en": "product", 
                            "tr": "ürün"
                        }, 
                        "value": "1"
                    }
                ], 
                "prefix": "", 
                "show": "yes", 
                "type": "select"
            }, 
            "4": {
                "name": {
                    "label": "madde", 
                    "translation": {
                        "en": "clause", 
                        "tr": "madde"
                    }, 
                    "value": "4"
                }, 
                "param_order": [
                    "5", 
                    "6", 
                    "7"
                ], 
                "parameters": {
                    "5": {
                        "name": {
                            "label": "maddelendirme düzeyi", 
                            "translation": {
                                "en": "itemization level", 
                                "tr": "maddelendirme düzeyi"
                            }, 
                            "value": "5"
                        }, 
                        "options": [
                            {
                                "label": "0", 
                                "translation": {
                                    "en": "0", 
                                    "tr": "0"
                                }, 
                                "value": "0"
                            }, 
                            {
                                "label": "1", 
                                "translation": {
                                    "en": "1", 
                                    "tr": "1"
                                }, 
                                "value": "1"
                            }, 
                            {
                                "label": "2", 
                                "translation": {
                                    "en": "2", 
                                    "tr": "2"
                                }, 
                                "value": "2"
                            }, 
                            {
                                "label": "3", 
                                "translation": {
                                    "en": "3", 
                                    "tr": "3"
                                }, 
                                "value": "3"
                            }, 
                            {
                                "label": "4", 
                                "translation": {
                                    "en": "4", 
                                    "tr": "4"
                                }, 
                                "value": "4"
                            }, 
                            {
                                "label": "5", 
                                "translation": {
                                    "en": "5", 
                                    "tr": "5"
                                }, 
                                "value": "5"
                            }, 
                            {
                                "label": "6", 
                                "translation": {
                                    "en": "6", 
                                    "tr": "6"
                                }, 
                                "value": "6"
                            }, 
                            {
                                "label": "7", 
                                "translation": {
                                    "en": "7", 
                                    "tr": "7"
                                }, 
                                "value": "7"
                            }, 
                            {
                                "label": "8", 
                                "translation": {
                                    "en": "8", 
                                    "tr": "8"
                                }, 
                                "value": "8"
                            }, 
                            {
                                "label": "9", 
                                "translation": {
                                    "en": "9", 
                                    "tr": "9"
                                }, 
                                "value": "9"
                            }, 
                            {
                                "label": "10", 
                                "translation": {
                                    "en": "10", 
                                    "tr": "10"
                                }, 
                                "value": "10"
                            }
                        ], 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "select"
                    }, 
                    "6": {
                        "name": {
                            "label": "açıklama", 
                            "translation": {
                                "en": "info", 
                                "tr": "açıklama"
                            }, 
                            "value": "6"
                        }, 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "textarea"
                    }, 
                    "7": {
                        "name": {
                            "label": "ürün sıralama", 
                            "translation": {
                                "en": "pro order type", 
                                "tr": "ürün sıralama"
                            }, 
                            "value": "7"
                        }, 
                        "options": [
                            {
                                "label": "ürün öncesi", 
                                "translation": {
                                    "en": "before product", 
                                    "tr": "ürün öncesi"
                                }, 
                                "value": "0"
                            }, 
                            {
                                "label": "ürün sonrası", 
                                "translation": {
                                    "en": "post product", 
                                    "tr": "ürün sonrası"
                                }, 
                                "value": "1"
                            }
                        ], 
                        "prefix": "", 
                        "show": "yes", 
                        "type": "select"
                    }
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "list_parameter"
            }, 
            "8": {
                "name": {
                    "label": "mağaza client id", 
                    "translation": {
                        "en": "shop client id", 
                        "tr": "mağaza client id"
                    }, 
                    "value": "8"
                }, 
                "prefix": "", 
                "show": "yes", 
                "type": "text"
            }
        }
    }, 
    "about": [], 
    "bmi_gfr": "no", 
    "calculate_eval": "no", 
    "checked_on_open": "no", 
    "date_ability": "yes", 
    "date_ability_limit": "none", 
    "date_ability_type": "date", 
    "date_ability_user_selection": "no", 
    "document_upload": "no", 
    "ending_date_ability": "yes", 
    "ending_date_ability_limit": "none", 
    "ending_date_ability_type": "date", 
    "ending_date_ability_user_selection": "no", 
    "favorite_show_mode": "yes", 
    "language": {
        "label": "Türkçe", 
        "value": "tr"
    }, 
    "model": "wisdom_data", 
    "module": [], 
    "name": {
        "label": "wdm_name !!!", 
        "translation": {
            "en": "user shop bundle aggreement", 
            "tr": "kullanıcı mağaza paket sözleşme"
        }, 
        "value": "ly + Created Layer Id !!!"
    }, 
    "owner": {
        "label": "katman", 
        "value": "layer"
    }, 
    "owner_type": "layer", 
    "param_group": {
        "param_order": [
            "0"
        ], 
        "parameters": {
            "0": {
                "label": "Param", 
                "value": "0"
            }
        }
    }, 
    "parent": "ly + Created Layer Id", 
    "record_count": "many_times", 
    "status": "published", 
    "style": {
        "caption": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 12
        }, 
        "value": {
            "after": "", 
            "backgroundColor": "white", 
            "border": {
                "color": "black", 
                "size": 0, 
                "type": "solid"
            }, 
            "borderRadius": 0, 
            "color": "black", 
            "fontSize": 10
        }
    }, 
    "value": "ly + Created Layer Id", 
    "version": "1.0"
};
export { data }
