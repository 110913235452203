import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  shop_bundle_update_by_excel (query, data) {
    let route = '';
    if (query) {
      route = 'v1/shop/bundle/update/by_excel?' + query;
    } else {
      route = 'v1/shop/bundle/update/by_excel';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/get?' + query;
    } else {
      route = 'v1/user/shop/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/list?' + query;
    } else {
      route = 'v1/user/shop/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_new (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/new?' + query;
    } else {
      route = 'v1/user/shop/new';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_edit (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/edit?' + query;
    } else {
      route = 'v1/user/shop/edit';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/get?' + query;
    } else {
      route = 'v1/user/shop/bundle/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/get?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/list?' + query;
    } else {
      route = 'v1/user/shop/bundle/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_order_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/order/list?' + query;
    } else {
      route = 'v1/user/shop/order/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/list?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_new (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/new?' + query;
    } else {
      route = 'v1/user/shop/bundle/new';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_edit (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/edit?' + query;
    } else {
      route = 'v1/user/shop/bundle/edit';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_new (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/new?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/new';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_edit (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/edit?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/edit';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_add (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/add?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/add';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/list?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_order_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/order/list?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/order/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_active_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/active/list?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/active/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_product_order (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/product/order?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/product/order';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_cancel (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/cancel?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/cancel';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_payment_record (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/payment/record?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/payment/record';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_status_change (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/status/change?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/status/change';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_product_status_change (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/product/status/change?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/product/status/change';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shopcart_bundle_product_payment_status_change (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shopcart/bundle/product/payment/status/change?' + query;
    } else {
      route = 'v1/user/shopcart/bundle/product/payment/status/change';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_agreement_new (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/agreement/new?' + query;
    } else {
      route = 'v1/user/shop/bundle/agreement/new';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_agreement_edit (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/agreement/edit?' + query;
    } else {
      route = 'v1/user/shop/bundle/agreement/edit';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_agreement_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/agreement/get?' + query;
    } else {
      route = 'v1/user/shop/bundle/agreement/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_agreement_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/agreement/list?' + query;
    } else {
      route = 'v1/user/shop/bundle/agreement/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_agreement_new (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/agreement/new?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/agreement/new';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_agreement_edit (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/agreement/edit?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/agreement/edit';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_agreement_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/agreement/get?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/agreement/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  user_shop_bundle_product_agreement_list (query, data) {
    let route = '';
    if (query) {
      route = 'v1/user/shop/bundle/product/agreement/list?' + query;
    } else {
      route = 'v1/user/shop/bundle/product/agreement/list';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  bundle_product_and_agreement_list_all (query, data) {
    let route = '';
    if (query) {
      route = 'v1/bundle/product/and/agreement/list/all?' + query;
    } else {
      route = 'v1/bundle/product/and/agreement/list/all';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
};
