<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['shop_bundle_agreement_list'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin-bottom: 10px;">
          <b-col sm="12" lg="6"> </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button size="sm" @click="f_newUserShopBundleAgreement()" variant="primary">
              {{ StoreLangTranslation.data['new_user_shop_bundle_agreement'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="12">
            <b-form-input id="search" v-model="d_searchText" :placeholder="StoreLangTranslation.data['write_for_filtering'][StoreLang]"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="2">
            <strong> Id </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrShpBundAgreementWdm['0']['parameters']['0']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="7">
            <strong> {{ d_usrShpBundAgreementWdm['0']['parameters']['1']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="1"> </b-col>
        </b-row>
        <b-row v-for="(wdmr_item, wdmr_item_ind) in d_usrShpBundleAgreementList" v-if="f_searchList(wdmr_item)" @mouseover="d_hoverIndex = wdmr_item_ind" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; margin: 10px; border-bottom: solid 1px #dcdcdc;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc;'">
          <b-col sm="12" lg="2">
            <small style="color: blue;"> {{ wdmr_item.client_id }}</small>
          </b-col>
          <b-col sm="12" lg="2">
            
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['0'] && wdmr_item.data['0']['0']['val']['translation']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['0']['val']['translation'][StoreLang] }}</span>
            </template>
          
          </b-col>
          <b-col sm="12" lg="7">
            
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['1'] && wdmr_item.data['0']['1']['lang'] && wdmr_item.data['0']['1']['lang'][StoreLang]">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['1']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['1'] && wdmr_item.data['0']['1']['val']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['1']['val'] }}</span>
            </template>
          
          </b-col>
          <b-col sm="12" lg="1">
            <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
              <template slot="button-content">
                {{ StoreLangTranslation.data['process'][StoreLang] }}
              </template>
              <b-dropdown-item v-if="d_user && d_user.auth.indexOf('admin') !== -1" @click="f_editThisUserShopBundleAgreement(wdmr_item.client_id)"><i class="fa fa-edit"></i> {{ StoreLangTranslation.data['edit'][StoreLang] }} </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import store from '@/store';
import UserShopService from '@/services/user_shop';
import { data as usr_shp_bund_agr } from '@/options/usr_shp_bund_agr';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_bundle_agreement_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_userShopClientID: '',
      d_userShopBundleClientID: '',
      d_usrShpBundleAgreementList: [],
      d_usrShpBundAgreementWdm: usr_shp_bund_agr,
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.d_userShopClientID = this.$route.query.user_shop_client_id;
    this.d_userShopBundleClientID = this.$route.query.user_shop_bundle_client_id;
    if (this.d_user && this.d_user.username && this.d_userShopClientID && this.d_userShopBundleClientID) {
      this.f_userShopBundleAgreementList();
    }
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_usrShpBundAgreementWdm);
  },
  methods: {
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_newUserShopBundleAgreement: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_agreement_record'), query: { 'user_shop_bundle_client_id': this.d_userShopBundleClientID, 'user_shop_client_id': this.d_userShopClientID } });
    },
    f_editThisUserShopBundleAgreement: function (user_shop_bundle_agreement_client_id) {
      let route_path = this.f_getRoutePath('user_shop_bundle_agreement_record');
      // console.log('route_path : ', route_path);
      this.$router.push({ path: route_path, query: { 'user_shop_bundle_client_id': this.d_userShopBundleClientID, 'user_shop_bundle_agreement_client_id': user_shop_bundle_agreement_client_id, 'user_shop_client_id': this.d_userShopClientID } });
    },
    f_searchList: function (wdmr_item) {
      let res = false;
      if (this.d_searchText) {
        if (wdmr_item.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_userShopBundleAgreementList: function () {
      let query = 'user_shop_bundle_client_id=' + this.d_userShopBundleClientID;
      let data = {};
      UserShopService.user_shop_bundle_agreement_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_usrShpBundleAgreementList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

